<template>
  <div>
    <b-card>
      <!-- form -->
      <validation-observer ref="notifyManual">
        <b-form class="mt-2">
          <h4 class="mb-2">{{ $t("NotificacionesManuales") }}</h4>
          <b-row class="pl-2">
            <b-col cols="12">
                <validation-provider
                    #default="{ errors }"
                    :name="$t('TipoNotificacion')"
                >
                  <b-form-group
                      :label="$t('TipoNotificacion')"
                      label-for="task-event"
                  >
                    <b-form-radio-group
                        v-model="notify"
                        :options="optionsNotify"
                        class="demo-inline-spacing"
                        name="radio-validation"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-col>

            <b-col sm="8" class="pt-1" v-if="notify == 'email'">
                <validation-provider
                    #default="{ errors }"
                    :name="$t('MensajeAEnviar')"
                    rules="required"
                >
                  <b-form-group
                      :label="$t('MensajeAEnviar')"
                      label-for="task-description"
                  >
                    <quill-editor v-model="textmessage" />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-col>
            <b-col sm="8" class="pt-1" v-if="notify == 'sms'">
                <validation-provider
                    #default="{ errors }"
                    :name="$t('MensajeAEnviar')"
                    rules="required"
                >
                  <b-form-group
                      :label="$t('MensajeAEnviar')"
                      label-for="task-event"
                  >
                    <b-form-textarea
                        id="textarea"
                        v-model="textmessage"
                        :placeholder="$t('MensajeAEnviar')"
                        rows="3"
                        max-rows="6"
                        maxlength="70"
                    ></b-form-textarea>
                    <p>{{ textmessage.length }}/70</p>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-col>

            <b-col cols="12" v-if="notify && currentRole == 'admin_empresa'">
                <validation-provider #default="{ errors }" :name="$t('Cliente')">
                  <b-form-group
                      :label="$t('Cliente')"
                      label-for="account-clients"
                  >
                    <v-select
                        label="name"
                        :filterable="true"
                        :searchable="true"
                        :options="selectClients"
                        v-model="client"
                        :placeholder="$t('TodosClientes')"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-col>

            <b-col cols="12" v-if="notify">
                <validation-provider #default="{ errors }" :name="$t('Roles')">
                  <b-form-group :label="$t('Roles')" label-for="task-event">
                    <b-form-checkbox-group
                        v-model="rol"
                        :options="selectRoles"
                        class="demo-inline-spacing"
                        name="radio-validation"
                        value-field="id"
                        text-field="display_name"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-col>
            <!--<b-col cols="12" v-if="notify && currentRole != 'admin_empresa'">

                <validation-provider
                    #default="{ errors }"
                    :name="$t('Departamentos')"
                >
                  <b-form-group
                      :label="$t('Departamentos')"
                      label-for="task-event"
                  >
                    <b-form-checkbox-group
                        v-model="dept"
                        :options="selectDepartments[0].data"
                        class="demo-inline-spacing"
                        name="radio-validation"
                        value-field="id"
                        text-field="name"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-col>
            -->

            <b-col cols="12" class="text-right" v-if="notify">
              <b-button variant="primary" class="mt-2 mr-1" @click="handleSubmit">
                {{ $t("Enviar") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card v-if="currentRole == 'admin_cliente'">
      <!-- form -->
      <validation-observer ref="notifyAuto">
        <b-form class="mt-2">
          <h4 class="mb-2">{{ $t("NotificacionesAutomaticas") }}</h4>
          <b-row class="pl-2">
            <b-col cols="12">
                  <b-form-group
                      :label="$t('TipoNotificacion')"
                      label-for="task-event"
                  >
                    <b-form-checkbox
                        class="mt-1"
                        id="checkbox-notifyAutoCreate"
                        v-model="notifyAutoCreate"
                        name="checkbox-notifyAutoCreate"
                        :value="true"
                        :unchecked-value="false"
                    >
                      {{ $t('Notificar cuando un evento se crea') }}
                    </b-form-checkbox>
                  </b-form-group>
            </b-col>
            <b-col cols="12" class="pl-3" v-if="notifyAutoCreate">
                <validation-provider #default="{ errors }" :name="$t('Roles')">
                  <b-form-group :label="$t('Roles')" label-for="task-event">
                    <b-form-checkbox-group
                        v-model="rolAutoCreate"
                        :options="selectRoles"
                        class="demo-inline-spacing"
                        name="radio-validation"
                        value-field="id"
                        text-field="display_name"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-col>
            <b-col cols="12">

                  <b-form-group
                      label-for="task-event"
                  >
                    <b-form-checkbox
                        id="checkbox-notifyAutoChange"
                        v-model="notifyAutoChange"
                        name="checkbox-notifyAutoChange"
                        :value="true"
                        :unchecked-value="false"
                    >
                      {{ $t('Notificar cuando un evento cambia de estado') }}
                    </b-form-checkbox>
                  </b-form-group>

            </b-col>
            <b-col cols="12" class="pl-3" v-if="notifyAutoChange">
                <validation-provider #default="{ errors }" :name="$t('Roles')">
                  <b-form-group :label="$t('Roles')" label-for="task-event">
                    <b-form-checkbox-group
                        v-model="rolAutoChange"
                        :options="selectRoles"
                        class="demo-inline-spacing"
                        name="radio-validation"
                        value-field="id"
                        text-field="display_name"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-col>


            <b-col cols="12" class="text-right">
              <b-button variant="primary" class="mt-2 mr-1" @click="handleSubmitAuto">
                {{ $t("Guardar") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  BFormCheckbox,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupText,
  BFormRadioGroup,
  BFormTextarea,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone.vue";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BButton,
    BInputGroupText,
    BInputGroup,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ImageDropzone,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormCheckbox,
    BFormRadioGroup,
    quillEditor,
    BFormTextarea,
    BFormCheckboxGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      companies: "",
      client: null,
      roles: "",
      //departments: [],
      textmessage: "",
      notify: null,
      optionsNotify: [
        { text: "Email", value: "email" },
        { text: "SMS", value: "sms" },
      ],
      notifyAutoCreate: false,
      notifyAutoChange: false,
      rol: [],
      rolAutoChange: [],
      rolAutoCreate: [],
      dept: [],
    };
  },
  computed: {
    ...mapGetters({
      currentClient: "clients/getCurrentClient",
      clientGetter: "clients/getClient",
      selectCompanies: "companies/getListCompanies",
      selectRoles: "users/getRoles",
      selectClients: "clients/getSelectClients",
      //selectDepartments: "departments/getSelectDepartments",
      currentRole: "auth/getRole",
      currentCompany: "companies/getCurrentCompany",
    }),
  },

  methods: {
    ...mapActions({
      create: "clients/notifymanual",
      createAuto: "clients/notifyauto",
      searchCompanies: "companies/searchCompanies",
      searchClients: "clients/selectClients",
      //getSelectDepartments: "departments/selectDepartments",
      getRoles: "users/getRoles",
      getClient: "clients/getClient",
    }),
    ...mapMutations({
      //setSelectDepartments: "departments/setSelectDepartments",
    }),
    setData(){
      if(this.clientGetter){
        if(this.clientGetter.notify_auto_event_change_status){
          this.notifyAutoChange = true
          this.rolAutoChange= this.clientGetter.notify_auto_event_change_status
        }
        if(this.clientGetter.notify_auto_event_create){
          this.notifyAutoCreate = true
          this.rolAutoCreate= this.clientGetter.notify_auto_event_create
        }
      }
    },
    handleSubmit() {
      this.$refs.notifyManual.validate().then((success) => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.create({ notify: formData })
          }
        }
      });
    },
    handleSubmitAuto() {
      this.$refs.notifyAuto.validate().then((success) => {
        if (success) {
          const formData = this.createFormDataAuto();
          if (formData) {
            this.createAuto({ notify: formData });
          }
        }
      });
    },
    createFormData() {
      const data = new FormData();

      if (this.currentRole == "admin_empresa") {
        if (this.client) {
          data.append("client_id", this.client.id);
        }
      } else {
        data.append("client_id", this.currentClient.id);
      }

      data.append("type", this.notify);
      data.append("text", this.textmessage);

      if (this.rol.length > 0) {
        this.rol.forEach((element) => {
          data.append("roles[]", element);
        });
      } else {
        data.append("roles", "");
      }

      /*if (this.dept.length > 0) {
        this.dept.forEach((element) => {
          data.append("departments[]", element);
        });
      } else {
        data.append("departments", "");
      }*/

      return data;
    },
    createFormDataAuto() {
      const data = new FormData();

      data.append("client_id", this.currentClient.id);

      if (this.notifyAutoCreate && this.rolAutoCreate.length > 0) {
        this.rolAutoCreate.forEach((element) => {
          data.append("notify_auto_event_create[]", element);
        });
      }
      if (this.notifyAutoChange && this.rolAutoChange.length > 0) {
        this.rolAutoChange.forEach((element) => {
          data.append("notify_auto_event_change_status[]", element);
        });
      }
      return data;
    },
  },
  async created() {
    await this.getClient(this.currentClient.id);
    await this.getRoles();
    if (this.currentRole === "admin_empresa") {
      await this.searchClients({
        company: 1,
      });
    } /*else {
      await this.getSelectDepartments({
        clients: [this.currentClient]
      });
    }*/
    await this.setData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
